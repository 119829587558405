.search-form[data-v-4afa1cd2] {
  background: #FFFFFF;
  padding: 14px 12px;
}
.search-form[data-v-4afa1cd2] .el-form-item--small .el-form-item__content {
  line-height: 28px !important;
}
.search-form[data-v-4afa1cd2] .el-button {
  height: 28px;
  margin-left: 0;
}
.search-form[data-v-4afa1cd2] .el-input__inner {
  height: 28px;
  line-height: 28px;
}
.right-toolbar[data-v-4afa1cd2] {
  float: right;
}
.tree-wrapper[data-v-4afa1cd2] {
  position: absolute;
  overflow-y: auto;
  height: 100%;
  left: 5px;
  right: 5px;
}
.tree-wrapper[data-v-4afa1cd2] .custom-tree-node {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}
.tree-wrapper[data-v-4afa1cd2] .custom-tree-node .icon-shujuku {
  color: #20a0ff;
}
.tree-wrapper[data-v-4afa1cd2] .custom-tree-node .icon-shujubiao {
  color: #38dcec;
}
[data-v-4afa1cd2] .search-form .el-form-item--small.el-form-item {
  margin-bottom: 0;
}